import React from 'react';
import Laptop from '../../assets/laptop.jpg';
import LaptopRemoveBg from '../../assets/laptop-removebg.png';

const Analytics = () => {
  return (<>
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[500px] mx-auto my-4' src={Laptop} alt='/' />
        <div className='flex flex-col justify-center'>
          <p className='text-[#00df9a] font-bold '>INCOME TAX RETURNS</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Income Tax Returns In Easy Steps</h1>
          <p>
            File your Income Tax Return hassle free and easy steps with Truly Tax Solution.
            We believe in complete transparency and confidentiality,
            and we work according to these ethics. To provide the best services to our 
            valued clients and to meet their expectations, we use state of the art infrastructure.
            This helps them to have confidence in us.
          </p>
          <button className='bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Get Started</button>
        </div>
      </div>
    </div>
    <div className='w-full bg-grey py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
      <div className='flex flex-col justify-center'>
          <p className='text-[#00df9a] font-bold '>GST Registration & Return</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2 text-white'>Hassle Free GST Registration</h1>
          <p className='text-slate-100'>
            Concentrate on your Business and let Truly Tax Solution handle your GST Return.
            We believe in complete transparency and confidentiality,
            and we work according to these ethics. To provide the best services to our 
            valued clients and to meet their expectations, we use state of the art infrastructure.
            This helps them to have confidence in us.
          </p>
          <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Get Started</button>
        </div>
        <img className='w-[500px] mx-auto my-4' src={LaptopRemoveBg} alt='/' />
      </div>
    </div>
    </>
  );
};

export default Analytics;
