import ReactTyped from "react-typed";
import Layout from "../../components/Layout/Layout";

export const Company = () => {
  return (
    <Layout>
    <div className='text-white'>
      <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <div className='flex flex-col justify-center items-center'>
          <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>
            Hi This is Company Page
          </p>
          <p className='text-[#00df9a] py-4'>
          <ReactTyped
          className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2'
            strings={['Proprietorship', 'Partnership Firm', 'One Person Company','Limited Liability Partnership',]}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
          </p>
        </div>
      </div>
    </div>
    </Layout>
  );
};
