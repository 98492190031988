import React from "react";

import Navbar from "../NavBar/Navbar";
import Footer from "../Footer/Footer";

export default function Layout({ children }) {
  return (
    <>
      <div>
        <div className="overlay"></div>
        <div className="stars" aria-hidden="true"></div>
        <div className="starts2" aria-hidden="true"></div>
        <div className="stars3" aria-hidden="true"></div>
        <Navbar />
        {children}
        <Footer />
      </div>
    </>
  );
}
