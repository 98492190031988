import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import logo from '../../assets/truly-tax-solution-high-resolution-logo-color-on-transparent-background.svg';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
      {/* <h1 className='w-full text-3xl font-bold text-[#00df9a]'>Truly Tax Solution</h1> */}
      <div className='w-[200px] lg:w-[300px] mt-8 justify-start'>
        <img src={logo} alt="siteLogo" />
        {/* <img className='block sm:hidden' src={logoCircle} alt="siteLogo" /> */}
      </div>
      <ul className='hidden md:flex'>
        <li className="p-4"><Link to="/">Home</Link></li>
        <li className="p-4"><Link to="/company">Company</Link></li>
        <li className="p-4"><Link to="/resources">Resources</Link></li>
        <li className="p-4"><Link to="/about">About</Link></li>
        <li className="p-4"><Link to="/contact">Contact</Link></li>
      </ul>
      <div onClick={handleNav} className='block md:hidden'>
          {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />} 
      </div>
      <ul className={nav ? 'fixed left-0 top-0 w-[90%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
      <div className='w-[200px] md:w-[100px] mt-8 mx-auto justify-center'>
        <img className='' src={logo} alt="siteLogo" />
      </div>
        <li className="p-4"><Link to="/">Home</Link></li>
        <li className="p-4"><Link to="/company">Company</Link></li>
        <li className="p-4"><Link to="/resources">Resources</Link></li>
        <li className="p-4"><Link to="/about">About</Link></li>
        <li className="p-4"><Link to="/contact">Contact</Link></li>
      </ul>
    </div>
  );
};

export default Navbar;
