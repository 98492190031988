import Analytics from "../../components/Analytics/Analytics";
import Cards from "../../components/Cards/Cards";
import Hero from "../../components/Hero/Hero";
import Layout from "../../components/Layout/Layout";

export const Home = () => {
  return (
    <Layout>
      {/* <div className="container"> */}
        <Hero />
        <Analytics />
        <Cards />
      {/* </div> */}
    </Layout>
  );
};
