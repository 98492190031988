import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { About, Company, Contact, Home, Resources } from "./pages";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/company" element={<Company/>} />
        <Route path="/resources" element={<Resources/>} />
        <Route path="/about" element={<About />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

